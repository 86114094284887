import React from 'react';

export default function Cross() {
    return (
        <svg viewBox="0 0 168.000000 168.000000">
            <g fill="inherit" transform="translate(0.000000,168.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M743 1596 c14 -172 91 -364 203 -504 51 -64 133 -140 222 -205 8 -7
38 2 91 27 44 19 100 41 125 48 25 7 46 15 46 18 0 3 -29 18 -64 33 -127 57
-279 205 -345 336 -40 78 -67 173 -76 259 l-7 72 -100 0 -101 0 6 -84z"/>
                <path d="M658 1351 c-110 -225 -334 -379 -592 -406 l-66 -7 0 -101 0 -100 84
6 c262 21 543 182 683 393 l34 52 -29 58 c-16 33 -37 84 -47 114 -10 30 -20
58 -24 61 -3 3 -23 -28 -43 -70z"/>
                <path d="M1527 930 c-124 -21 -287 -88 -394 -161 -61 -42 -158 -139 -212 -213
l-43 -58 35 -77 c20 -42 41 -97 48 -123 6 -27 14 -48 18 -47 3 0 23 35 43 77
109 226 333 379 596 407 l62 7 0 99 0 99 -52 -1 c-29 -1 -75 -5 -101 -9z"/>
                <path d="M415 765 c-38 -19 -91 -39 -117 -46 -27 -7 -48 -15 -47 -18 0 -3 35
-23 77 -43 222 -106 382 -338 407 -585 l7 -73 100 0 101 0 -6 84 c-15 186 -97
379 -231 540 -44 52 -193 177 -211 175 -5 0 -41 -16 -80 -34z"/>
            </g>
        </svg>
    );
}
