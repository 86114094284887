import React from 'react';

export default function Endian() {
    return (
        <svg viewBox="0 0 168.000000 168.000000">
            <g transform="translate(0.000000,168.000000) scale(0.100000,-0.100000)" fill="inherit" stroke="none">
                <path d="M740 1516 l0 -164 -47 -12 c-81 -20 -159 -69 -228 -142 -102 -108
-145 -213 -145 -358 0 -142 42 -249 139 -352 105 -113 231 -168 381 -168 144
0 256 47 366 152 103 98 154 221 154 368 0 149 -44 254 -155 366 -72 74 -139
115 -219 135 l-46 11 0 164 0 164 -100 0 -100 0 0 -164z m226 -386 c23 -10 60
-35 83 -56 173 -155 130 -420 -84 -524 -69 -33 -181 -33 -250 0 -137 66 -212
205 -186 344 13 70 32 106 84 163 69 75 153 106 257 96 30 -2 74 -13 96 -23z"/>
            </g>
        </svg>
    );
}
